@font-face {
  font-family: 'Bad Script';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/badscript-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/pacifico-regular.ttf') format('truetype');
}

body {
  font-family: lato, sans-serif;
  font-weight: 300;
}

.pointer {
  cursor: pointer;
}

h1 {
  font-size: 2em;
  font-weight: 700;
  font-family: 'Bad Script', cursive;
  text-align: center;
}

.header {
  .navbar {
    padding-top: 0;
    padding-bottom: 0;

    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 3em;
    }

    .logo {
      height: 90px;
      max-width: 100%;
    }

    .navbar-nav {
      max-width: 34em;

      .nav-link {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        color: #000 !important;

        &:hover {
          color: #8ce6dd !important;
        }
      }
    }
  }

  .intro-wrapper {
    @media (min-width: 992px) {
      margin-top: -121px;
    }

    z-index: -1;
    display: flex;

    .intro {
      margin-top: 2em;

      @media (min-width: 992px) {
        margin-top: 150px;
      }

      flex-grow: 1;

      img.logo {
        height: 150px;
      }

      .intro-description {
        @media (min-width: 992px) {
          padding-right: 5em;
        }

        margin-bottom: 5em;

        .intro-description-title {
          font-family: "Bad Script", cursive;
          font-size: 1.3em;
        }

        ul {
          list-style-type: none;
          padding-inline-start: .5em;
          margin-block-start: .5em;
          margin-block-end: .5em;
        }
      }

      .button-order-wrapper {
        position: relative;
        text-align: right;
        margin-bottom: 8em;

        img {
          position: absolute;
          z-index: -1;
          right: 0;
          top: 2.4em;
          transform: rotate(80deg);
          width: 10em;
          margin-top: -5em;
        }

        a {
          cursor: pointer;
          background: #e1ccc6;
          color: #fff;
          font-size: 2em;
          padding: .1em 1em;
          font-weight: 300;
          outline: none;
          border: none;

          &:hover {
            box-shadow: 0 5px 30px 0 rgba(0,0,0,0.2);
            background-color: #8ce6dd;
          }
        }
      }
    }

    .drawing {
      position: relative;
      z-index: -1;
      flex-grow: 1;
      width: 70em;
      height: 25em;
      background-color: #fcf2f3;

      .photo {
        max-width: 50%;
        position: absolute;
        left: -10%;
        bottom: -10%;

      }

      .leaves {
        max-width: 50%;
        height: 40em;
        opacity: .3;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

.benefits {
  background: #fcf2f3;

  .cards {
    .card {
      width: 15em;
      max-width: 15em;

      .card-icon {
        width: 3em;
        height: 3em;
        border-radius: 3em;
      }
    }
  }
}

.order {
  .order-description {
    padding: 2em 0;

    .oreder-title {
      margin: 2em 0;
      padding: 0 1em;
    }

    ul {
      list-style-type: none;
      padding-inline-start: .5em;
      margin-block-start: .5em;
      margin-block-end: .5em;
    }
  }
}

.portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .portfolio-card {
    width: 20em;
    margin-right: 30px;
    margin-bottom: 2em;

    > div {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 1em;
      }

      .portfolio-description {
        position: absolute;
        background: #fff;
        border-radius: .3em;
        padding: .5em 1em;
        left: 1em;
        right: 1em;
        transition: 300ms all cubic-bezier(.4,0,.2,1);
        opacity: 0;
        bottom: -9em;

        .portfolio-title {
          font-size: 1em;
          font-weight: 900;
        }
      }
    }

    &:hover .portfolio-description {
      opacity: .9;
      bottom: 1em;
    }
  }
}

.prices {
  .cards {
    .card {
      width: 25em;
      max-width: 25em;

      .card-icon {
        width: 7em;
        height: 7em;
        border-radius: 7em;
      }
    }
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .card {
    margin-bottom: 2em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background: #fff;
    border-radius: 1em;
    transition: 300ms all cubic-bezier(.4,0,.2,1);
    border: none !important;

    &:hover {
      transform: translateY(-30px);
      box-shadow: 0 8px 60px 0 rgba(153, 135, 131, 0.11), 0 12px 90px 0 rgba(153, 135, 131, 0.11);
    }

    .card-icon {
      background: #fcf2f3;
      margin-bottom: 1em;
    }

    .card-list-wrapper {
      margin-bottom: 2em;
      text-align: left;
      flex-grow: 1;
    }

    ul {
      list-style-type: none;
      padding-inline-start: .5em;
      margin-block-start: .5em;
      margin-block-end: .5em;
    }

    li {
      display: flex;
      align-items: center;

      img {
        margin-top: -.5em;
        margin-right: .5em;
      }
    }

    .card-order-button {
      background: #e1ccc6;
      color: #fff;
      font-size: 2em;
      padding: .1em 1em;
      font-weight: 300;
      outline: none;
      border: none;

      &:hover {
        background-color: #8ce6dd;
        box-shadow: 0 5px 30px 0 rgb(0 0 0 / 20%);
      }
    }
  }
}

.about {
  .about-title {
    .about-line-left, .about-line-right {
      width: 5em;
      border-bottom: 2px solid #ececec;
    }
  }

  .about-description {
    max-width: 50em;
  }

  img {
    height: 20em;
  }
}

.order-form {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 80%;
    }
  }

  .button-form {
    background-color: #e1ccc6;
    border: none;
    color: #fff;

    &:hover {
      background-color: #8ce6dd;
      border: none;
      color: #fff;
      box-shadow: 0 5px 30px 0 rgb(0 0 0 / 20%);
    }
  }
}

.btn-go-up {
  position: fixed;
  right: 1em;
  bottom: 6em;
  width: 3em;
  height: 3em;
  background: #fff;
  border-radius: .5em;
  color: #8ce6dd;
  border: 1pt solid #8ce6dd;
  display: flex;
  transition: all .5s;
  text-decoration: none;

  &:hover {
    background: #8ce6dd;
    color: #fff;
    border: 1pt solid #fff;
  }

  i {
    margin: auto;
  }
}
