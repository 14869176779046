.form-control {
  &.form-name {
    box-shadow: none;
  }

  &.price-comment {
    min-height: 7em;
    box-shadow: none;
  }

  &.my-border-color {
    border-color: #8ce6dd;
  }
}

